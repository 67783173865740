
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import DropdownComponent from '@/components/Forms/Elements/DropdownComponent.vue';
import { DropdownOptions } from '@/models/Forms/FormOption';
import { Recruiter } from '@/models/Entities/Personas/Recruiter';

@Component<ApplicantSelectRecruiterDropDown>({
  components: { DropdownComponent }
})
export default class ApplicantSelectRecruiterDropDown extends Vue {
  @Prop({ default: null })
  readonly applicant!: { id: number; managerId: number } | null;

  @Prop({ required: false })
  readonly updateDelegate?: () => void;

  get recruiters(): Recruiter[] {
    return this.$store.getters['recruiterModule/collection'] || [];
  }

  get recruiterId(): number | undefined {
    return this.applicant?.managerId;
  }

  get dropdownOptions(): DropdownOptions[] {
    return this.recruiters?.map((recruiter) => {
      return {
        label: recruiter.fullName,
        value: recruiter.id || 0
      };
    });
  }

  async handleApplicantRecruiterChange(
    selectedRecruiterId?: number
  ): Promise<void> {
    if (
      this.applicant?.id &&
      this.applicant.managerId !== selectedRecruiterId
    ) {
      await this.$store.dispatch('applicantModule/patchApplicant', {
        applicantId: this.applicant.id,
        payload: { manager: { id: selectedRecruiterId } || null }
      });
      if (this.updateDelegate) {
        this.updateDelegate();
      }
    }
  }
}
